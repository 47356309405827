<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">实习岗位管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-select size="small" v-model="retrievalData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="岗位名称" class="searchboxItem ci-full">
              <span class="itemLabel">岗位名称:</span>
              <el-input v-model="retrievalData.batchJobName" placeholder="请输入岗位名称" type="text" clearable size="small" />
            </div>
            <div title="发布状态" class="searchboxItem ci-full">
              <span class="itemLabel">发布状态:</span>
              <el-select v-model="retrievalData.batchState" placeholder="请选择发布状态" clearable size="small">
                <el-option v-for="item in batchStateList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="setAddAndEdit('', 1)">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="岗位名称" align="left" show-overflow-tooltip prop="batchJobName" width="260" />
              <el-table-column label="企业名称" align="left" show-overflow-tooltip prop="compName" width="260" />
              <el-table-column label="实习人数" align="left" show-overflow-tooltip prop="batchInternNum" width="100" />
              <el-table-column label="实习期" align="left" show-overflow-tooltip width="200">
                <template slot-scope="scope">
                  {{ scope.row.batchStartDate | momentDate }}至{{ scope.row.batchEndDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="报名截止日期" align="left" show-overflow-tooltip prop="batchSignDeadline" width="150">
                <template slot-scope="scope">
                  {{ scope.row.batchSignDeadline | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="发布状态" align="left" show-overflow-tooltip prop="batchState" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.batchState == '10'">未发布</span>
                  <span v-else-if="scope.row.batchState == '20'">已发布</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="报名人数" align="left" show-overflow-tooltip prop="peopleNum" width="100" />
              <el-table-column label="报名状态" align="left" show-overflow-tooltip prop="occupationName" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.applyStatus == '0'">进行中</span>
                  <span v-else-if="scope.row.applyStatus == '1'">已结束</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="140" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setAddAndEdit(scope.row, 2)">复制</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setAddAndEdit(scope.row, 3)">编辑</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="releaseData(scope.row)">{{
                    scope.row.batchState == '20' ? '回收' : '发布' }}</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="seeData(scope.row)">查看报名</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="deleteData(scope.row)"
                    :disabled="scope.row.batchState == '20'">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog :title="addAndEditData.dialogTitle" :visible.sync="addAndEditData.dialogVisible" width="500px"
      :destroy-on-close="true" :close-on-click-modal="false" @close="cancelData">
      <el-form ref="addAndEditData" class="addAndEditData" :model="addAndEditData" :rules="addAndEditDataRules"
        label-width="120px" size="small" :before-close="cancelData">
        <el-form-item label="企业名称" prop="compId">
          <el-select size="small" v-model="addAndEditData.compId" remote :remote-method="getCompanyList1" filterable
            clearable placeholder="请选择企业">
            <el-option v-for="item in CompanyList1" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位名称" prop="batchJobName">
          <el-input v-model="addAndEditData.batchJobName" placeholder="请输入岗位名称"></el-input>
        </el-form-item>
        <el-form-item label="实习人数" prop="batchInternNum">
          <el-input-number style="width:200px;" v-model="addAndEditData.batchInternNum" :min="1" clearable
            placeholder="请输入实习人数" />
        </el-form-item>
        <el-form-item label="岗位薪酬描述" prop="batchJobDescribe">
          <el-input class="setHeight_180" v-model="addAndEditData.batchJobDescribe" type="textarea" max maxlength="1000"
            show-word-limit placeholder="请输入岗位薪酬描述" />
        </el-form-item>
        <el-form-item label="福利待遇" prop="welfareTreatment">
          <el-select size="small" v-model="addAndEditData.welfareTreatment" multiple :multiple-limit="4" filterable
            clearable placeholder="请选择福利待遇">
            <el-option v-for="item in welfareTreatmentList" :key="item.recruitmentLabelId" :label="item.labelName"
              :value="item.recruitmentLabelId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实习期" prop="batchTime">
          <el-date-picker v-model="addAndEditData.batchTime" type="daterange" size="small" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="报名截止日期" prop="batchSignDeadline">
          <el-date-picker clearable v-model="addAndEditData.batchSignDeadline" type="date" size="small"
            value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelData">取消</el-button>
        <el-button class="bgc-bv" size="small" @click="saveData">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder_internshipPositionManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        compId: "", // 机构名称
        batchJobName: "", // 岗位名称
        batchState: "", // 发布状态
      },
      // 企业名称 - 下拉数据
      CompanyList: [],
      // 发布状态 - 下拉数据
      batchStateList: [{
        value: '10',
        label: '未发布'
      }, {
        value: '20',
        label: '已发布'
      }],
      // 新增&编辑&复制 - 弹框
      addAndEditData: {
        dialogVisible: false, // 弹框显示
        dialogTitle: "", // 弹框标题
        compId: "", // 企业名称id
        batchJobName: "", // 岗位名称
        batchInternNum: "", // 实习人数
        batchJobDescribe: "", // 岗位薪酬描述
        welfareTreatment: [], // 福利待遇
        batchTime: [], // 实习期
        batchSignDeadline: "", // 报名截止日期
      },
      // 新增&编辑&复制 - 企业名称 - 下拉数据
      CompanyList1: [],
      // 新增&编辑&复制 - 福利待遇 - 下拉数据
      welfareTreatmentList: [],
      // 新增&编辑&复制 - 数据校验
      addAndEditDataRules: {
        compId: [
          { required: true, message: "请选择企业", trigger: "change" },
        ],
        batchJobName: [
          { required: true, message: "请输入岗位名称", trigger: "blur" },
        ],
        batchInternNum: [
          { required: true, message: "请输入实习人数", trigger: "blur" },
        ],
        batchJobDescribe: [
          { required: true, message: "请输入岗位薪酬描述", trigger: "blur" },
        ],
        welfareTreatment: [
          { required: true, message: "请选择福利待遇", trigger: "change" },
        ],
        batchTime: [
          { required: true, message: "请选择实习期", trigger: "change" },
        ],
        batchSignDeadline: [
          { required: true, message: "请选择报名截止日期", trigger: "change" },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getWelfareTreatmentList();
  },
  computed: {},
  mounted() { },
  methods: {
    // 企业名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/intern/company/queryCompanyList", { compName: query }, 3000, true, 7)
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      if (this.retrievalData.batchJobName) {
        params.batchJobName = this.retrievalData.batchJobName;
      }
      if (this.retrievalData.batchState) {
        params.batchState = this.retrievalData.batchState;
      }
      this.doFetch({
        url: "/biz/batch/intern-job/pageList",
        params,
        pageNum,
      }, true, 7);
    },
    // 新增&编辑&复制 - 弹框-打开
    setAddAndEdit(row, type) {
      // 新增
      if (type == 1) {
        this.addAndEditData.dialogTitle = '新增岗位';
      }
      // 复制
      if (type == 2) {
        this.addAndEditData = { ... this.addAndEditData, ...row };
        this.addAndEditData.dialogTitle = '复制岗位';
        delete this.addAndEditData.batchId;
        this.addAndEditData.batchTime = [row.batchStartDate.replaceAll('/', '-'), row.batchEndDate.replaceAll('/', '-')];
        this.addAndEditData.batchSignDeadline = row.batchSignDeadline.replaceAll('/', '-');
        this.CompanyList1 = [{
          compName: row.compName,
          compId: row.compId
        }]
        if (row.welfareTreatment) {
          this.addAndEditData.welfareTreatment = row.welfareTreatment.split(",");
        }
      }
      // 编辑
      if (type == 3) {
        this.addAndEditData = { ... this.addAndEditData, ...row };
        this.addAndEditData.dialogTitle = '编辑岗位';
        this.addAndEditData.batchTime = [row.batchStartDate.replaceAll('/', '-'), row.batchEndDate.replaceAll('/', '-')];
        this.addAndEditData.batchSignDeadline = row.batchSignDeadline.replaceAll('/', '-');;
        this.CompanyList1 = [{
          compName: row.compName,
          compId: row.compId
        }]
        if (row.welfareTreatment) {
          this.addAndEditData.welfareTreatment = row.welfareTreatment.split(",");
        }
      }
      this.addAndEditData.dialogVisible = true;
    },
    // 新增&编辑&复制 - 企业名称
    getCompanyList1(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/intern/company/queryCompanyList", { compName: query }, 3000, true, 7)
          .then(res => {
            if (res.status == 0) {
              this.CompanyList1 = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList1 = [];
      }
    },
    // 新增&编辑&复制 - 福利待遇 - 获取下拉数据
    getWelfareTreatmentList() {
      this.$post("/biz/batch/intern-batch/getLabel", {}, 3000, true, 7)
        .then(res => {
          if (res.status == 0) {
            this.welfareTreatmentList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 新增&编辑&复制 - 弹框-取消
    cancelData() {
      this.addAndEditData = this.$options.data().addAndEditData;
    },
    // 新增&编辑&复制 - 弹框-确认
    saveData() {
      this.$refs['addAndEditData'].validate((valid) => {
        if (valid) {
          let url = "";
          let cData = {
            ...this.addAndEditData
          }
          cData.welfareTreatment = cData.welfareTreatment.join(",");
          cData.batchStartDate = cData.batchTime[0];
          cData.batchEndDate = cData.batchTime[1];
          if (this.addAndEditData.batchId) {
            url = '/biz/batch/intern-job/modify';
          } else {
            url = '/biz/batch/intern-job/insert';
          }
          this.$post(url, cData, 3000, true, 7).then((res) => {
            if (this.addAndEditData.batchId) {
              this.getData(this.pageNum);
            } else {
              this.getData();
            }
            this.$message.success("操作成功");
            this.cancelData();
          }).catch(() => {
            return;
          });
        }
      });
    },
    // 发布
    releaseData(row) {
      this.$post("/biz/batch/intern-job/publish", { batchId: row.batchId }, 3000, true, 7).then(
        res => {
          this.$message({
            type: "success",
            message: row.batchState == '20' ? '回收成功' : '发布成功',
          });
          this.getData(this.pageNum);
        }
      );
    },
    // 查看报名
    seeData(row) {
      this.$router.push({
        path: '/web/workOrder/internshipPositionManagementInfo',
        query: {
          batchId: row.batchId, // 批次id
        }
      });
    },
    // 删除
    deleteData(row) {
      this.$confirm("您确定删除么, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/batch/intern-job/removeComp", { batchId: row.batchId }, 3000, true, 7).then(
            res => {
              this.getData(this.pageNum);
              this.$message.success("删除成功");
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    }
  },
  beforeRouteLeave: resetKeepAlive,
}
</script>
<style lang="less" scope>
.addAndEditData {
  .el-form-item {
    .el-form-item__content {
      .el-input-number {
        width: 100%;
      }
    }
  }

  .setHeight_180 {
    .el-textarea__inner {
      min-height: 180px !important;
    }
  }

  .el-textarea .el-input__count {
    background: transparent;
  }
}
</style>